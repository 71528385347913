import { Fragment, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  matchPath,
  useHistory,
} from "react-router-dom";
import gql from "graphql-tag";
import { captureMessage } from "@sentry/react";
import { useQuery } from "@apollo/react-hooks";
import { useFeatureFlags } from "FeatureFlags";
import {
  AcceptInvitation,
  AppLayoutPage,
  AgreementsPage,
  AvidPaymentPage,
  BillComPaymentPage,
  BorrowerAppLayoutPage,
  BorrowerPortfolioPage,
  BorrowerProjectOverviewPage,
  BorrowerDrawPage,
  BorrowerNewDrawPage,
  BudgetPage,
  BudgetUploadPage,
  ChatPage,
  ConfigurationPage,
  DeltekPaymentPage,
  DrawDocumentsPage,
  DrawFundingSourcesPage,
  DrawHardCostsPage,
  DrawInspectionPage,
  DrawBudgetPage,
  DrawOverviewPage,
  DrawReviewsPage,
  DrawSoftCostsPage,
  ConfirmPage,
  ErrorPage,
  GuestInspectionReportPage,
  GuestUploadPage,
  ImportDrawSubmissionPage,
  ImportReportSubmissionPage,
  MriPaymentPage,
  NexusPaymentPage,
  NewDrawPage,
  NewUserPage,
  NotFoundPage,
  NotificationReminderPage,
  NotificationSettingsPage,
  PortfolioPage,
  ProjectDocumentsPage,
  ProjectionsPage,
  ProjectLayout,
  ProjectOverviewPage,
  ProjectDashboard,
  PortfolioInsights,
  ProjectSettingsPage,
  QuickBooksOnlinePaymentPage,
  RegisterPage,
  RegisterBorrowerPage,
  ReportsDocumentsPage,
  ReportsDrawsPage,
  ReportsFundingSourcesPage,
  ReportsJobCostCodesPage,
  ReportsTasksPage,
  PortfolioInsightsPage,
  ReportsProjectsPage,
  ReportsInvoiceSummaryPage,
  ReportSubmissionPage,
  SplitUploadPage,
  SubmissionDocumentsPage,
  SubmissionPageLayout,
  SubmissionPreviewPage,
  TimelinePage,
  UsersPage,
  VendorsPage,
  YardiPaymentPage,
} from "components/containers";
import { OnboardingWizardLayout } from "components/containers/OnboardingWizard/OnboardingWizardLayout";
import { Loadable } from "components/materials";
import {
  PermissionRoute,
  UploadContextProvider,
  UserContextProvider,
  UserContext,
} from "helpers/behaviors";
import { FileUploadProvider } from "features/uploads";
import { PERMISSION_ACTION } from "helpers/enums";
import { setRecentProjectIds } from "helpers/localStorage";
import analytics from "helpers/analytics";
import { get, some, startsWith } from "lodash";
import { validate as isValidUUID } from "uuid";
import { useAuth } from "./Auth";
import useQueryParams from "./hooks/useQueryParams";

const BORROWER_CHECK = gql`
  query BorrowerCheck {
    borrower {
      id
    }
  }
`;

function documentRoutes(path) {
  return [`${path}/documents/:documentId`];
}

function lineItemRoutes(path) {
  const lineItemPath = `${path}/line_items/:lineItemId`;
  return [lineItemPath, ...documentRoutes(lineItemPath)];
}

function superLineItemRoutes(path) {
  const superLineItemPath = `${path}/summary_line_items/:superLineItemId`;
  return [superLineItemPath, ...documentRoutes(superLineItemPath)];
}

function Login() {
  const auth = useAuth();
  const history = useHistory();
  const params = useQueryParams();

  if (auth.isLoading)
    return <Loadable loading spinnerText="Checking login status..." />;

  if (!auth.isAuthenticated()) {
    auth.reset();
    // Connection is used in the case of an SSO login. If undefined, it will
    // default to the database connection.
    auth.login({ connection: params.connection });
  } else {
    history.push("/");
  }
  return <Loadable loading spinnerText="Redirecting to login..." />;
}

function Logout() {
  const auth = useAuth();
  auth.logout();
  analytics.track("User Signed Out");
  return <Loadable loading spinnerText="Logging you out..." />;
}

function AuthCallback() {
  const auth = useAuth();

  if (auth.isLoading) {
    return <Loadable loading spinnerText="Verifying user data..." />;
  }

  if (auth.isAuthenticated()) {
    return <Loadable loading spinnerText="Logging you in..." />;
  }

  captureMessage(
    `AuthCallback user authentication failed: ${JSON.stringify(auth)}`
  );
  return <Redirect to="/logout" />;
}

function Register(props) {
  const auth = useAuth();
  auth.resetReturnTo();
  auth.reset();
  return <RegisterPage {...props} />;
}

function RegisterBorrower(props) {
  const auth = useAuth();
  auth.resetReturnTo();
  auth.reset();
  return <RegisterBorrowerPage {...props} />;
}

function Submission(props) {
  const { path } = props.match;
  return (
    <SubmissionPageLayout {...props}>
      <Switch>
        <Route exact path={path} component={SubmissionPreviewPage} />
        {/* Submission pages other than the SubmissionPreviewPage are no longer supported */}
        <Route
          exact
          path={[`${path}/overview`, ...lineItemRoutes(`${path}/overview`)]}
          component={DrawBudgetPage}
        />
        <Route
          exact
          path={[
            `${path}/hard_costs`,
            ...documentRoutes(`${path}/hard_costs`),
            ...lineItemRoutes(`${path}/hard_costs`),
          ]}
          component={DrawHardCostsPage}
        />
        <Route
          exact
          path={[
            `${path}/soft_costs`,
            ...documentRoutes(`${path}/soft_costs`),
            ...lineItemRoutes(`${path}/soft_costs`),
          ]}
          component={DrawSoftCostsPage}
        />
        <Route
          exact
          path={[`${path}/documentation`, `${path}/documentation/:documentId`]}
          component={SubmissionDocumentsPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </SubmissionPageLayout>
  );
}

function getPaymentRoute(hasPermissionAcrossProfiles) {
  if (
    hasPermissionAcrossProfiles(PERMISSION_ACTION.VISION_DELTEK_AP_INTEGRATION)
  )
    return "deltek";
  if (hasPermissionAcrossProfiles(PERMISSION_ACTION.AVID_AP_INTEGRATION))
    return "avid";
  if (hasPermissionAcrossProfiles(PERMISSION_ACTION.YARDI_INTEGRATION))
    return "yardi";
  if (hasPermissionAcrossProfiles(PERMISSION_ACTION.NEXUS_INTEGRATION))
    return "nexus";
  if (hasPermissionAcrossProfiles(PERMISSION_ACTION.PUSH_TO_QUICK_BOOKS_ONLINE))
    return "quick_books_online";
  if (hasPermissionAcrossProfiles(PERMISSION_ACTION.PUSH_TO_BILL))
    return "bill_com";
  if (hasPermissionAcrossProfiles(PERMISSION_ACTION.PUSH_TO_MRI)) return "mri";
  return "not_found";
}

function Draw(props) {
  const { path } = props.match;

  return (
    <UserContext.Consumer>
      {({ hasPermission, hasPermissionAcrossProfiles }) => (
        <Switch>
          <Redirect
            exact
            from={path}
            to={
              hasPermission(PERMISSION_ACTION.DRAW_OVERVIEW_PAGE)
                ? `${path}/overview`
                : `${path}/line_items`
            }
          />
          <Redirect
            exact
            from={`${path}/documents`}
            to={`${path}/documentation`}
          />
          <Redirect
            exact
            from={`${path}/payment`}
            to={`${path}/payment/${getPaymentRoute(
              hasPermissionAcrossProfiles
            )}`}
          />
          <PermissionRoute
            permission={PERMISSION_ACTION.DRAW_OVERVIEW_PAGE}
            exact
            path={`${path}/overview`}
            component={DrawOverviewPage}
          />
          <Route
            exact
            path={[
              `${path}/line_items`,
              ...lineItemRoutes(path),
              ...superLineItemRoutes(path),
            ]}
            component={DrawBudgetPage}
          />
          <Route
            exact
            path={[
              `${path}/hard_costs`,
              ...documentRoutes(`${path}/hard_costs`),
              ...lineItemRoutes(`${path}/hard_costs`),
            ]}
            component={DrawHardCostsPage}
          />
          <Route
            exact
            path={[
              `${path}/soft_costs`,
              ...documentRoutes(`${path}/soft_costs`),
              ...lineItemRoutes(`${path}/soft_costs`),
            ]}
            component={DrawSoftCostsPage}
          />
          <Route
            exact
            path={[`${path}/reviews`, `${path}/reviews/:ruleId`]}
            component={DrawReviewsPage}
          />
          <PermissionRoute
            permission={PERMISSION_ACTION.AVID_AP_INTEGRATION}
            exact
            path={`${path}/payment/avid`}
            component={AvidPaymentPage}
          />
          <PermissionRoute
            permission={PERMISSION_ACTION.YARDI_INTEGRATION}
            exact
            path={`${path}/payment/yardi`}
            component={YardiPaymentPage}
          />
          <PermissionRoute
            permission={PERMISSION_ACTION.NEXUS_INTEGRATION}
            exact
            path={`${path}/payment/nexus`}
            component={NexusPaymentPage}
          />
          <PermissionRoute
            permission={PERMISSION_ACTION.PUSH_TO_MRI}
            exact
            path={`${path}/payment/mri`}
            component={MriPaymentPage}
          />
          <PermissionRoute
            permission={PERMISSION_ACTION.PUSH_TO_QUICK_BOOKS_ONLINE}
            exact
            path={`${path}/payment/quick_books_online`}
            component={QuickBooksOnlinePaymentPage}
          />
          <PermissionRoute
            permission={PERMISSION_ACTION.PUSH_TO_BILL}
            exact
            path={`${path}/payment/bill_com`}
            component={BillComPaymentPage}
          />
          <PermissionRoute
            permission={PERMISSION_ACTION.VISION_DELTEK_AP_INTEGRATION}
            exact
            path={`${path}/payment/deltek`}
            component={DeltekPaymentPage}
          />
          <Route
            exact
            path={[
              `${path}/documentation`,
              `${path}/documentation/:documentId`,
            ]}
            component={DrawDocumentsPage}
          />
          <PermissionRoute
            permission={PERMISSION_ACTION.ACCESS_FUNDING_SOURCES}
            exact
            path={`${path}/funding_sources`}
            component={DrawFundingSourcesPage}
          />
          <PermissionRoute
            permission={PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW}
            exact
            path={`${path}/inspection`}
            component={DrawInspectionPage}
          />
          <Route component={NotFoundPage} />
        </Switch>
      )}
    </UserContext.Consumer>
  );
}

function Project(props) {
  const { projectId } = props.match.params;
  const { path } = props.match;
  setRecentProjectIds(projectId);

  const featureFlags = useFeatureFlags();
  const useNewDashboard = featureFlags.isEnabled(
    "use-redesigned-project-dashboard"
  );

  return (
    <ProjectLayout {...props}>
      <Switch>
        <Redirect
          exact
          from={`${path}/documents`}
          to={`${path}/documentation`}
        />
        <Route
          exact
          path={[path, `${path}/assigned_documents/:documentId`]}
          component={useNewDashboard ? ProjectDashboard : ProjectOverviewPage}
        />
        <Route
          exact
          path={[path, `${path}/assigned_documents/:documentId`]}
          component={useNewDashboard ? ProjectDashboard : ProjectOverviewPage}
        />
        <Route
          exact
          path={[
            ...lineItemRoutes(`${path}/budget`),
            ...superLineItemRoutes(`${path}/budget`),
            `${path}/budget`,
          ]}
          component={BudgetPage}
        />
        <Route
          exact
          path={[`${path}/documentation`, `${path}/documentation/:documentId`]}
          component={ProjectDocumentsPage}
        />
        <PermissionRoute
          permission={PERMISSION_ACTION.AGREEMENT_MANAGEMENT}
          exact
          path={[
            `${path}/agreements`,
            `${path}/agreements/:agreementId`,
            `${path}/agreements/:vendorId/:lineItemId`,
            `${path}/agreements/:vendorId/:lineItemId/:documentId`,
          ]}
          component={AgreementsPage}
        />
        <PermissionRoute
          permission={PERMISSION_ACTION.TASK_MANAGEMENT}
          exact
          path={[`${path}/timeline`, `${path}/timeline/:taskId`]}
          component={TimelinePage}
        />
        <PermissionRoute
          permission={PERMISSION_ACTION.EDIT_PROJECT_SETTINGS}
          exact
          path={`${path}/settings`}
          component={ProjectSettingsPage}
        />
        <PermissionRoute
          analyticsTracker={{
            event: "Projection Line Items Viewed",
            data: { projectId },
          }}
          permission={PERMISSION_ACTION.PROJECT_FORECASTING}
          exact
          path={`${path}/projection`}
          component={ProjectionsPage}
        />
        <PermissionRoute
          permission={PERMISSION_ACTION.CREATE_DRAW}
          exact
          path={`${path}/draws/new`}
          component={NewDrawPage}
        />
        <Route path={`${path}/draws/:drawId`} component={Draw} />
        <Route component={NotFoundPage} />
      </Switch>
    </ProjectLayout>
  );
}

function Report(props) {
  const { path } = props.match;

  return (
    <Fragment>
      <Switch>
        <PermissionRoute
          permission={[
            PERMISSION_ACTION.RUN_REPORT,
            PERMISSION_ACTION.TASK_MANAGEMENT,
          ]}
          exact
          path={[`${path}/milestones`, `${path}/tasks`]}
          component={ReportsTasksPage}
        />
        <PermissionRoute
          permission={PERMISSION_ACTION.RUN_REPORT}
          exact
          path={`${path}/job_cost_codes`}
          component={ReportsJobCostCodesPage}
        />
        <PermissionRoute
          permission={PERMISSION_ACTION.RUN_REPORT}
          exact
          path={[
            `${path}/invoice_summary`,
            `${path}/invoice_summary/projects/:projectId/documents/:documentId`,
          ]}
          component={ReportsInvoiceSummaryPage}
        />
        <PermissionRoute
          permission={PERMISSION_ACTION.RUN_REPORT}
          exact
          path={`${path}/draws`}
          component={ReportsDrawsPage}
        />
        <PermissionRoute
          permission={PERMISSION_ACTION.RUN_REPORT}
          exact
          path={[
            `${path}/documentation`,
            `${path}/documentation/projects/:projectId/documents/:documentId`,
          ]}
          component={ReportsDocumentsPage}
        />
        <PermissionRoute
          permission={PERMISSION_ACTION.RUN_REPORT}
          exact
          path={`${path}/projects`}
          component={ReportsProjectsPage}
        />
        <PermissionRoute
          permission={PERMISSION_ACTION.ACCESS_FUNDING_SOURCES}
          exact
          path={`${path}/funding_sources`}
          component={ReportsFundingSourcesPage}
        />
        <PermissionRoute
          permission={[
            PERMISSION_ACTION.ADVANCED_REPORT,
            PERMISSION_ACTION.RUN_REPORT,
          ]}
          exact
          path={`${path}`}
          component={PortfolioInsights}
        />
        {/* TODO: Remove this route once completely rolled over to new Portfolio Insights */}
        <PermissionRoute
          permission={[
            PERMISSION_ACTION.ADVANCED_REPORT_LENDER,
            PERMISSION_ACTION.RUN_REPORT,
          ]}
          exact
          path={`${path}/lender`}
          component={PortfolioInsightsPage}
        />
        {/* TODO: Remove this route once completely rolled over to new Portfolio Insights */}
        <PermissionRoute
          permission={[
            PERMISSION_ACTION.ADVANCED_REPORT_DEVELOPER,
            PERMISSION_ACTION.RUN_REPORT,
          ]}
          exact
          path={`${path}/developer`}
          component={PortfolioInsightsPage}
        />
      </Switch>
    </Fragment>
  );
}

function Admin(props) {
  const { path } = props.match;

  return (
    <Fragment>
      <Switch>
        <PermissionRoute
          permission={PERMISSION_ACTION.MANAGE_USER}
          exact
          path={`${path}/users/new`}
          component={NewUserPage}
        />
        <PermissionRoute
          permission={PERMISSION_ACTION.MANAGE_USER}
          exact
          path={[path, `${path}/users/:userId?`]}
          component={UsersPage}
        />
        <PermissionRoute
          permission={PERMISSION_ACTION.CONFIGURE_ORGANIZATION}
          exact
          path={`${path}/configure`}
          component={ConfigurationPage}
        />
      </Switch>
    </Fragment>
  );
}

function BorrowerProject(props) {
  const { projectId } = props.match.params;
  const { path } = props.match;
  setRecentProjectIds(projectId);

  return (
    <Switch>
      <Route exact path={path} component={BorrowerProjectOverviewPage} />
      <Route exact path={`${path}/draws/new`} component={BorrowerNewDrawPage} />
      <Route
        exact
        path={[`${path}/draws/:drawId`]}
        component={BorrowerDrawPage}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
}

function BorrowerRoutes() {
  // for full Users, intercom is installed in the UserContext
  analytics.initializeIntercom();

  return (
    <BorrowerAppLayoutPage>
      <Switch>
        <Route exact path="/borrower" component={BorrowerPortfolioPage} />
        <Route
          path="/borrower/projects/:projectId"
          component={BorrowerProject}
        />
        <Redirect exact from="/" to="/borrower" />
        <Route component={NotFoundPage} />
      </Switch>
    </BorrowerAppLayoutPage>
  );
}

function AppLayoutRoutes() {
  const location = useLocation();
  const auth = useAuth();
  const { data, loading: borrowerCheckLoading } = useQuery(BORROWER_CHECK);

  const unauthenticatedRouteMatches = unauthenticatedAppLayoutRoutes.map(
    (route) => matchPath(location.pathname, route)
  );

  const isUnauthenticatedRoute = some(
    unauthenticatedRouteMatches,
    (match) => !!match
  );
  if (isUnauthenticatedRoute) {
    return (
      <AppLayoutPage>
        <Switch>
          <Route
            exact
            path="/remind_in/:scheduledNotificationId"
            component={NotificationReminderPage}
          />
          <Route
            exact
            path="/guest/:accessTokenId"
            component={GuestUploadPage}
          />
          <Route
            exact
            path="/report_submissions/:reportSubmissionId"
            component={ReportSubmissionPage}
          />
          <Route path="/submissions/:submissionId" component={Submission} />
        </Switch>
      </AppLayoutPage>
    );
  }

  if (startsWith(window.location.href, "http:")) {
    window.location.href = window.location.href.replace("http:", "https:");
    return <Loadable loading spinnerText="Redirecting to secure site" />;
  }

  if (auth.isLoading)
    return <Loadable loading spinnerText="Verifying user data..." />;

  if (!auth.isAuthenticated()) {
    auth.setReturnTo(window.location.pathname);
    return <Redirect to="/login" />;
  }

  auth.updateLastActivity();

  // at this point, we must be logged in, etiher as a regular user or a borrower user.
  // therefore, we know that if the borrowerCheck returns empty, we must be a regular user.
  if (borrowerCheckLoading)
    return <Loadable loading spinnerText="Verifying user permissions" />;

  if (data?.borrower) {
    return (
      <UserContextProvider>
        <BorrowerRoutes />;
      </UserContextProvider>
    );
  }

  return (
    <UserContextProvider>
      <FileUploadProvider>
        <AppLayoutPage>
          <UploadContextProvider>
            <Switch>
              <Route
                exact
                path={[
                  "/",
                  "/portfolio_assigned_documents/:projectId/:documentId",
                ]}
                component={PortfolioPage}
              />
              <Route
                exact
                path="/accept_invitation/:invitationToken"
                component={AcceptInvitation}
              />
              <PermissionRoute
                permission={PERMISSION_ACTION.VIEW_ORGANIZATIONS}
                exact
                path={[
                  "/organizations",
                  "/organizations/:vendorId",
                  "/organizations/:vendorId/projects/:projectId/documents/:documentId",
                ]}
                component={VendorsPage}
              />
              <Route
                exact
                path="/notifications"
                component={NotificationSettingsPage}
              />
              <PermissionRoute
                permission={PERMISSION_ACTION.AI_CHATBOT}
                exact
                path={["/vantage"]}
                component={ChatPage}
              />
              <Route
                exact
                path="/report_submissions/:reportSubmissionId/import"
                component={ImportReportSubmissionPage}
              />
              <Route
                exact
                path="/draw_submissions/:submissionId/import"
                component={ImportDrawSubmissionPage}
              />
              <Route
                path="/projects/:projectId/configure"
                component={OnboardingWizardLayout}
              />
              <Route
                path="/projects/:projectId/budget_upload"
                component={BudgetUploadPage}
              />
              <Route
                path="/projects/:projectId/confirm_budget"
                component={ConfirmPage}
              />
              <Route path="/projects/:projectId" component={Project} />
              <Route path="/reports" component={Report} />
              <Redirect exact from="/users" to="/admin" />
              <Redirect from="/projects/new" to="/" />
              <Route path="/uploads/:uploadId" component={SplitUploadPage} />
              <Route path="/admin" component={Admin} />
              <Route component={NotFoundPage} />
            </Switch>
          </UploadContextProvider>
        </AppLayoutPage>
      </FileUploadProvider>
    </UserContextProvider>
  );
}

function Routes() {
  const location = useLocation();
  useEffect(() => {
    analytics.page();
  }, [location.pathname]);

  const routeMatches = allUUIDRoutes.map((route) =>
    matchPath(location.pathname, route)
  );

  const routeExceptions = [
    "/users/new",
    "/draws/new",
    "/organizations/new",
    "/admin/configure",
  ];

  const isException = routeExceptions.some((exception) =>
    location.pathname.includes(exception)
  );

  const routeHasInvalidUUID =
    !isException &&
    routeMatches.some((match) => {
      const params = get(match, "params", {});
      return some(params, (value) => {
        return !isValidUUID(value);
      });
    });

  return (
    <Fragment>
      <Switch>
        <Route exact path="/error" component={ErrorPage} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/auth/callback" component={AuthCallback} />
        <Route exact path="/register/:invitationToken" component={Register} />
        <Route
          exact
          path="/register_borrower/:invitationToken"
          component={RegisterBorrower}
        />
        <Route
          exact
          path="/guest/inspection_report/:inspectionReportId"
          component={GuestInspectionReportPage}
        />
        {routeHasInvalidUUID && <Route component={NotFoundPage} />}
        <Route component={AppLayoutRoutes} />
      </Switch>
    </Fragment>
  );
}

const allUUIDRoutes = [
  "/register/:invitationToken",
  "/register_borrower/:invitationToken",
  "/guest/inspection_report/:inspectionReportId",
  "/remind_in/:scheduledNotificationId",
  "/guest/:accessTokenId",
  "/report_submissions/:reportSubmissionId",
  "/submissions/:submissionId",
  "/submissions/:submissionId/overview/line_items/:lineItemId",
  "/submissions/:submissionId/overview/line_items/:lineItemId/documents/:documentId",
  "/submissions/:submissionId/hard_costs/line_items/:lineItemId",
  "/submissions/:submissionId/hard_costs/line_items/:lineItemId/documents/:documentId",
  "/submissions/:submissionId/hard_costs/documents/:documentId",
  "/submissions/:submissionId/soft_costs/line_items/:lineItemId",
  "/submissions/:submissionId/soft_costs/line_items/:lineItemId/documents/:documentId",
  "/submissions/:submissionId/soft_costs/documents/:documentId",
  "/submissions/:submissionId/documentation/:documentId",
  "/portfolio_assigned_documents/:projectId/:documentId",
  "/accept_invitation/:invitationToken",
  "/organizations/:vendorId",
  "/organizations/:vendorId/projects/:projectId/documents/:documentId",
  "/report_submissions/:reportSubmissionId/import",
  "/draw_submissions/:submissionId/import",
  "/projects/:projectId/configure",
  "/projects/:projectId",
  "/projects/:projectId/assigned_documents/:documentId",
  "/projects/:projectId/budget/line_items/:lineItemId",
  "/projects/:projectId/budget/line_items/:lineItemId/documents/:documentId",
  "/projects/:projectId/budget/summary_line_items/:superLineItemId",
  "/projects/:projectId/documentation/:documentId",
  "/projects/:projectId/agreements/:agreementId",
  "/projects/:projectId/agreements/:vendorId/:lineItemId",
  "/projects/:projectId/agreements/:vendorId/:lineItemId/:documentId",
  "/projects/:projectId/draws/:drawId",
  "/projects/:projectId/draws/:drawId/line_items/:lineItemId",
  "/projects/:projectId/draws/:drawId/line_items/:lineItemId/documents/:documentId",
  "/projects/:projectId/draws/:drawId/summary_line_items/:superLineItemId",
  "/projects/:projectId/draws/:drawId/summary_line_items/:superLineItemId/documents/:documentId",
  "/projects/:projectId/draws/:drawId/hard_costs/line_items/:lineItemId",
  "/projects/:projectId/draws/:drawId/hard_costs/line_items/:lineItemId/documents/:documentId",
  "/projects/:projectId/draws/:drawId/hard_costs/documents/:documentId",
  "/projects/:projectId/draws/:drawId/soft_costs/line_items/:lineItemId",
  "/projects/:projectId/draws/:drawId/soft_costs/line_items/:lineItemId/documents/:documentId",
  "/projects/:projectId/draws/:drawId/soft_costs/documents/:documentId",
  "/projects/:projectId/draws/:drawId/reviews/:ruleId",
  "/projects/:projectId/draws/:drawId/documentation/:documentId",
  "/admin/users/:userId",
  "/reports/invoice_summary/projects/:projectId/documents/:documentId",
  "/reports/documentation/projects/:projectId/documents/:documentId",
  "/borrower/projects/:projectId",
  "/borrower/projects/:projectId/draws/:drawId",
];

const unauthenticatedAppLayoutRoutes = [
  "/remind_in/:scheduledNotificationId",
  "/guest/:accessTokenId",
  "/report_submissions/:reportSubmissionId",
  "/submissions/:submissionId",
  "/submissions/:submissionId/overview/line_items/:lineItemId",
  "/submissions/:submissionId/overview/line_items/:lineItemId/documents/:documentId",
  "/submissions/:submissionId/hard_costs/line_items/:lineItemId",
  "/submissions/:submissionId/hard_costs/line_items/:lineItemId/documents/:documentId",
  "/submissions/:submissionId/hard_costs/documents/:documentId",
  "/submissions/:submissionId/soft_costs/line_items/:lineItemId",
  "/submissions/:submissionId/soft_costs/line_items/:lineItemId/documents/:documentId",
  "/submissions/:submissionId/soft_costs/documents/:documentId",
  "/submissions/:submissionId/documentation/:documentId",
];

export default Routes;
