import { useContext, useState, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  camelCase,
  get,
  pick,
  pickBy,
  reduce,
  startsWith,
  values,
} from "lodash";
import { Formik } from "formik";
import {
  Button,
  Confirm,
  Form,
  Loadable,
  Pane,
  Sidebar,
} from "components/materials";
import { UserContext } from "helpers/behaviors";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import unformatNumber from "helpers/unformatNumber";
import isBlank from "helpers/isBlank";
import isInvalidEmail from "helpers/isInvalidEmail";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import {
  userAssignablePermissions,
  formatPermissions,
} from "helpers/permissionsHelpers";
import { PERMISSION_ACTION } from "helpers/enums";
import * as Currency from "../../../Currency";
import UserForm from "../../../User/UserForm";
import { USERS_PAGE_QUERY } from "./UsersPage";

const QUERY = gql`
  query UserQuery($organizationId: String!, $userId: String!) {
    organization(id: $organizationId) {
      id
      teams {
        id
        memberships {
          id
          permissionConfig
          userId
        }
        name
      }
      permissionConfig
      userAssignablePermissions
      projects {
        id
        suggestedDocumentAssignees {
          id
          userId
        }
        documentReviewers {
          id
          userId
        }
        team {
          id
        }
        name
        drawReviewers {
          id
          isSignatory
        }
        status
      }
      user(id: $userId) {
        id
        approvalAmountLimit
        approvalAmountMinimum
        email
        firstName
        fullName
        isAssignedSignatory
        isSso
        lastName
        permissionConfig
        projectIds
        ssoRole
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser(
    $userId: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $memberships: [MembershipInput]
    $permissions: [PermissionInput]
    $approvalAmountMinimum: Currency
    $approvalAmountLimit: Currency
    $projectIds: [String]
  ) {
    updateUser(
      userId: $userId
      email: $email
      firstName: $firstName
      lastName: $lastName
      memberships: $memberships
      permissions: $permissions
      approvalAmountMinimum: $approvalAmountMinimum
      approvalAmountLimit: $approvalAmountLimit
      projectIds: $projectIds
    ) {
      id
      fullName
      email
      permissionConfig
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(userId: $userId) {
      id
    }
  }
`;

const GENERATE_API_TOKEN = gql`
  mutation generateApiToken($userId: String!) {
    generateApiToken(userId: $userId) {
      id
      apiToken
    }
  }
`;

const initialValues = (
  teams,
  {
    email, //
    firstName,
    id: userId,
    lastName,
    permissionConfig,
    approvalAmountMinimum,
    approvalAmountLimit,
    projectIds,
    ...rest
  }
) => {
  const teamMemberships = teams.reduce((obj, team) => {
    const membership = team.memberships.find(
      (membership) => membership.userId === userId
    );
    obj[`view-all-team-${team.id}`] = get(
      membership,
      "permissionConfig.viewAllTeamProjects",
      false
    );
    return obj;
  }, {});

  return {
    ...rest,
    ...teamMemberships,
    email: email || "",
    firstName: firstName || "",
    lastName: lastName || "",
    projectIds,
    permissions: values(PERMISSION_ACTION).reduce(
      (acc, action) => ({
        ...acc,
        [action]: get(permissionConfig, camelCase(action), false),
      }),
      {}
    ),
    approvalAmountMinimum: Currency.formatForInput(approvalAmountMinimum),
    approvalAmountLimit: Currency.formatForInput(approvalAmountLimit),
  };
};

const validate = (values) => {
  const errors = {};
  if (isBlank(values.email)) {
    errors.email = "Please enter an email";
  } else if (isInvalidEmail(values.email)) {
    errors.email = "Please enter a valid email";
  }
  if (isBlank(values.firstName)) errors.firstName = "Please enter a first name";
  if (isBlank(values.lastName)) errors.lastName = "Please enter a last name";
  if (values.permissions[PERMISSION_ACTION.APPROVE_DOCUMENTS]) {
    const approvalAmountMinimum =
      values.approvalAmountMinimum &&
      unformatNumber(values.approvalAmountMinimum);
    const approvalAmountLimit =
      values.approvalAmountMinimum &&
      unformatNumber(values.approvalAmountLimit);
    if (
      approvalAmountLimit &&
      approvalAmountMinimum &&
      approvalAmountLimit < approvalAmountMinimum
    ) {
      errors.approvalAmountLimit =
        "Please enter a number greater than the Minimum";
    }
  }
  return errors;
};

function DeleteUser({
  deleteUser,
  handleClose,
  handleOpen,
  isConfirmOpen,
  organizationId,
  user,
}) {
  return (
    <Fragment>
      <Confirm
        content={`Are you sure you want to delete ${user.fullName}? This action is irreversible.`}
        header="Delete User"
        onConfirm={(close) => {
          close();
          deleteUser({
            variables: { userId: user.id },
            refetchQueries: [
              {
                query: USERS_PAGE_QUERY,
                variables: { organizationId },
              },
            ],
          });
        }}
        open={isConfirmOpen}
        onCloseComplete={handleClose}
      />
      <Button
        appearance="minimal"
        intent="danger"
        marginTop={majorScale(2)}
        onClick={handleOpen}
        purpose="edit-user delete"
      >
        Delete User
      </Button>
    </Fragment>
  );
}

function SidebarInner({
  deleteUser,
  editUser,
  editUserResult,
  generateMutation,
  handleClose,
  handleOpen,
  isConfirmOpen,
  selectedOrganization,
  query,
}) {
  const [signatoryWarningOpen, setSignatoryWarningOpen] = useState(false);
  const { userId: currentUserId } = useContext(UserContext);

  if (query.loading) return <Loadable loading />;

  const user = get(query, "data.organization.user", {});
  const teams = get(query, "data.organization.teams", []);
  const projects = get(query, "data.organization.projects", []);

  const handleSubmit = ({
    email,
    permissions,
    projectIds,
    approvalAmountMinimum,
    approvalAmountLimit,
    ...rest
  }) => {
    const isTeamMembership = (_value, key) => startsWith(key, "view-all-team");
    const memberships = reduce(
      pickBy(rest, isTeamMembership),
      (acc, value, key) => {
        if (value)
          acc.push({
            // view-all-team- is 14 chars
            teamId: key.substring(14),
            permissionConfig: [
              { action: "VIEW_ALL_TEAM_PROJECTS", enabled: true },
            ],
          });
        return acc;
      },
      []
    );

    const mutationVariables = pick(rest, ["firstName", "lastName"]);

    editUser({
      variables: {
        ...mutationVariables,
        memberships,
        permissions: formatPermissions(permissions),
        userId: user.id,
        email: email.trim(),
        projectIds: permissions[PERMISSION_ACTION.VIEW_ALL_PROJECTS]
          ? []
          : projectIds,
        approvalAmountMinimum: Currency.parseForServer(approvalAmountMinimum),
        approvalAmountLimit: Currency.parseForServer(approvalAmountLimit),
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues(teams, user)}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {(form) => {
        const losingSignatory =
          !form.values.permissions[PERMISSION_ACTION.DRAW_SIGNATORY] &&
          form.initialValues.permissions[PERMISSION_ACTION.DRAW_SIGNATORY];
        const showSignatoryWarning =
          losingSignatory && user.isAssignedSignatory;

        return (
          <Form data-testid="user-form">
            <Sidebar.Heading breadcrumbs={["Edit User", user.fullName]}>
              {form.dirty && (
                <Pane display="flex">
                  <Pane>
                    <Button
                      onClick={() => form.handleReset()}
                      marginRight={majorScale(2)}
                      disabled={editUserResult.loading}
                      purpose="user-sidebar edit undo"
                    >
                      Undo
                    </Button>
                  </Pane>
                  <Pane>
                    <Form.SubmitButton
                      onClick={(e) => {
                        preventEventBubbling(e);
                        showSignatoryWarning
                          ? setSignatoryWarningOpen(true)
                          : form.handleSubmit();
                      }}
                      purpose="user-sidebar edit submit"
                      isLoading={editUserResult.loading}
                      label="Save"
                    />
                  </Pane>
                </Pane>
              )}
            </Sidebar.Heading>
            <Sidebar.Section>
              <UserForm
                {...editUserResult}
                formikProps={form}
                generateMutation={generateMutation}
                teams={teams}
                organizationPermissions={userAssignablePermissions(
                  get(query, "data.organization", {})
                )}
                projects={projects}
                user={user}
              />
              {currentUserId !== user.id && (
                <DeleteUser
                  deleteUser={deleteUser}
                  handleClose={handleClose}
                  handleOpen={handleOpen}
                  isConfirmOpen={isConfirmOpen}
                  organizationId={selectedOrganization.id}
                  user={user}
                />
              )}
            </Sidebar.Section>
            <Confirm
              cancelLabel="Cancel"
              confirmLabel="Save"
              content={t("editUser.disableDrawSignatoryWarning")}
              header="Edit User"
              onCloseComplete={() => setSignatoryWarningOpen(false)}
              onConfirm={form.handleSubmit}
              open={signatoryWarningOpen}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export function EditUserSidebar({
  history,
  match,
  closeSidebar,
  selectedOrganization,
}) {
  const open = !!match.params.userId;
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const query = useQuery(QUERY, {
    variables: {
      ...match.params,
      organizationId: selectedOrganization.id,
    },
    skip: !open,
  });
  const [editUser, editUserResult] = useMutation(UPDATE_USER, {
    onCompleted,
    refetchQueries: ["UsersPageQuery"],
  });
  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted,
  });

  const generateMutation = useMutation(GENERATE_API_TOKEN);

  function handleOpen() {
    setIsConfirmOpen(true);
  }

  function handleClose() {
    setIsConfirmOpen(false);
  }

  function onCompleted() {
    handleClose();
    history.push("/admin");
  }

  return (
    <Sidebar isShown={open} onCloseComplete={closeSidebar}>
      {open && (
        <SidebarInner
          deleteUser={deleteUser}
          editUser={editUser}
          editUserResult={editUserResult}
          generateMutation={generateMutation}
          handleClose={handleClose}
          handleOpen={handleOpen}
          isConfirmOpen={isConfirmOpen}
          match={match}
          onCompleted={onCompleted}
          query={query}
          selectedOrganization={selectedOrganization}
        />
      )}
    </Sidebar>
  );
}

EditUserSidebar.propTypes = {
  match: PropTypes.object.isRequired,
};
