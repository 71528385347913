import { useContext } from "react";
import { DocumentsViewer } from "components/templates";
import { Loadable } from "components/materials";
import {
  DocumentContext,
  DocumentContextProvider,
} from "contexts/documentContext";
import { UserContext } from "helpers/behaviors";
import { getSearchByKey } from "helpers/queryStringHelpers";
import { PERMISSION_ACTION } from "helpers/enums";
import { NotFoundPage } from "./NotFoundPage";

function InnerDocument({ match }) {
  const { documentId, lineItemId, projectId } = match.params;
  const { document, history } = useContext(DocumentContext);

  const handleClose = () => {
    const {
      location: { pathname, search },
      replace,
    } = history;
    if (pathname.includes("/organizations/")) {
      replace(
        pathname.replace(`/projects/${projectId}/documents/${documentId}`, "")
      );
    } else if (pathname.includes("/reports/documentation")) {
      replace({
        pathname: pathname.replace(
          `/projects/${projectId}/documents/${documentId}`,
          ""
        ),
        search: history.location.search,
      });
    } else if (pathname.includes("/agreements")) {
      const config = getSearchByKey(history, "table");
      replace({
        pathname: pathname.replace(`/${documentId}`, ""),
        search: config ? `?table=${config}` : undefined,
      });
    } else if (pathname.includes("/documentation/")) {
      const config = getSearchByKey(history, "table");
      replace({
        pathname: pathname.replace(`/${documentId}`, ""),
        search: config ? `?table=${config}` : undefined,
      });
    } else if (pathname.includes("/line_items/") && search.includes("direct")) {
      replace(
        pathname.replace(
          `/line_items/${lineItemId}/documents/${documentId}`,
          ""
        )
      );
    } else if (
      pathname.includes("/line_items/") ||
      pathname.includes("/summary_line_items/")
    ) {
      replace({
        pathname: pathname.replace(`/documents/${documentId}`, ""),
        search,
      });
    } else if (pathname.includes("/portfolio_assigned_documents/")) {
      replace(
        pathname.replace(
          `/portfolio_assigned_documents/${projectId}/${documentId}`,
          ""
        )
      );
    } else if (pathname.includes("/assigned_documents/")) {
      replace(pathname.replace(`/assigned_documents/${documentId}`, ""));
    } else {
      replace(pathname.replace(`/documents/${documentId}`, ""));
    }
  };

  if (!document) return <Loadable loading />;

  return <DocumentsViewer onClose={handleClose} />;
}

export function Document(props) {
  const { hasPermission } = useContext(UserContext);

  if (!hasPermission(PERMISSION_ACTION.DOWNLOAD_DOCUMENT)) {
    return <NotFoundPage />;
  }

  return (
    <DocumentContextProvider
      documents={props.documents}
      defaultGroup={props.defaultGroup}
      history={props.history}
      match={props.match}
      refetch={props.refetch}
    >
      <InnerDocument {...props} />
    </DocumentContextProvider>
  );
}

Document.defaultProps = { refetch: [] };
