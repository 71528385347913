import React from "react";
import { DragHandleVerticalIcon, EyeOffIcon, EyeOpenIcon } from "evergreen-ui";
import { majorScale, ThemeContext } from "helpers/utilities";
import analytics from "helpers/analytics";
import { IconButton, Pane } from "components/materials";

function updatedCards(cards, name, isDisabled) {
  const unchangedCards = cards.filter((card) => card.i !== name);
  const cardToUpdate = cards.filter((card) => card.i === name)[0];

  return [...unchangedCards, { ...cardToUpdate, disabled: isDisabled }];
}

export default function ConfigureButtons({
  isDisabled,
  cards,
  setCards,
  name,
}) {
  const theme = React.useContext(ThemeContext);

  const [cardDisabled, setCardDisabled] = React.useState(isDisabled);

  return (
    <Pane display="flex">
      <IconButton
        appearance="minimal"
        border={`1px solid ${theme.colors.gray400}`}
        icon={DragHandleVerticalIcon}
        marginRight={majorScale(1)}
      />
      {cardDisabled ? (
        <IconButton
          appearance="minimal"
          border={`1px solid ${theme.colors.gray400}`}
          icon={EyeOpenIcon}
          onClick={() => {
            analytics.track(`project dashboard ${name} enabled`);
            setCardDisabled(false);
            setCards(updatedCards(cards, name, false));
          }}
        />
      ) : (
        <IconButton
          appearance="minimal"
          border={`1px solid ${theme.colors.gray400}`}
          icon={EyeOffIcon}
          onClick={() => {
            analytics.track(`project dashboard ${name} disabled`);
            setCardDisabled(true);
            setCards(updatedCards(cards, name, true));
          }}
        />
      )}
    </Pane>
  );
}
