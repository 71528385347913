import {
  useState,
  useContext,
  Fragment,
  useMemo,
  useEffect,
  useReducer,
} from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { StickyHeaderTable } from "components/materials";
import { ThemeContext } from "helpers/utilities";
import { FastDataTableBody } from "./FastDataTableBody";
import { FastDataTableControls } from "./FastDataTableControls";
import { FastDataTableFooter } from "./FastDataTableFooter";
import { FastDataTableHeader } from "./FastDataTableHeader";
import { FastDataTablePagination } from "./FastDataTablePagination";
import {
  ensureValidSearchColumn,
  fromBase64,
  paginateItems,
  prepareColumns,
  prepareItems,
  toBase64,
} from "./FastDataTableUtils";

export function FastDataTable({
  columns,
  controls,
  disableCollapse,
  disableRowClick,
  empty,
  footerContent,
  footerTotals,
  getRowState,
  hideTableHeader,
  itemCountForPagination,
  items,
  onChange,
  onClickRow,
  onConfigChange,
  onSerialize,
  pageSize,
  prepareOnFrontend,
  serialized,
  setPage,
  simplePaginationControls,
  utilityColumns,
}) {
  const theme = useContext(ThemeContext);

  // This has side-effects, so it shouldn't be a reducer. Likely, the reducer should just handle the state and the side-effects should be handled in a useEffect.
  const [config, setConfig] = useReducer((state, action) => {
    let newState = { ...action.payload };

    newState = ensureValidSearchColumn(newState);

    if (!action.skipOnSerialize) {
      onSerialize(toBase64(newState));
    }
    if (action.type !== "changePage") {
      newState = {
        ...newState,
        pageConfig: { ...newState.pageConfig, page: 0 },
      };
    }
    if (onConfigChange) {
      onConfigChange(newState);
    }
    return newState;
  }, fromBase64(serialized));

  useEffect(() => {
    if (serialized !== toBase64(config)) {
      setConfig({
        payload: fromBase64(serialized),
        skipOnSerialize: true,
        type: "all",
      });
    }

    if (onConfigChange) {
      onConfigChange({
        pageConfig: { page: 0 },
        ...fromBase64(serialized),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialized]);

  const [selectedItemIds, setSelectedItemIds] = useState([]);

  const pageDisabled = !pageSize || !!config.groupConfig.columnId;
  const pageNumber = get(config, "pageConfig.page", 0);

  const preparedColumns = useMemo(() => {
    return prepareColumns(columns, config);
  }, [columns, config]);

  const preparedItems = useMemo(() => {
    const result = prepareOnFrontend
      ? prepareItems(items, columns, config)
      : items;
    onChange(result);
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, config, items]);

  const paginatedItems = useMemo(() => {
    return prepareOnFrontend
      ? paginateItems(preparedItems, pageDisabled, pageNumber, pageSize)
      : preparedItems;
  }, [pageDisabled, pageNumber, pageSize, preparedItems, prepareOnFrontend]);

  const showFooterTotals = footerTotals && items.length > 0;

  return (
    <Fragment>
      <StickyHeaderTable
        bodyProps={{ allowHorizontalScroll: true }}
        header={
          <FastDataTableHeader
            config={config}
            hideTableHeader={hideTableHeader}
            preparedColumns={preparedColumns}
            preparedItems={preparedItems}
            selectedItemIds={selectedItemIds}
            setConfig={setConfig}
            setSelectedItemIds={setSelectedItemIds}
            theme={theme}
            utilityColumns={utilityColumns}
          />
        }
        tableControls={(scrollRefs) => (
          <FastDataTableControls
            columns={columns}
            config={config}
            controls={controls}
            items={items}
            preparedColumns={preparedColumns}
            preparedItems={preparedItems}
            scrollRefs={scrollRefs}
            selectedItemIds={selectedItemIds}
            serialized={serialized}
            setConfig={setConfig}
            setSelectedItemIds={setSelectedItemIds}
            showFooterTotals={showFooterTotals}
          />
        )}
      >
        <Fragment>
          <FastDataTableBody
            config={config}
            disableCollapse={disableCollapse}
            disableRowClick={disableRowClick}
            empty={empty}
            getRowState={getRowState}
            onClickRow={onClickRow}
            preparedColumns={preparedColumns}
            preparedItems={paginatedItems}
            selectedItemIds={selectedItemIds}
            setConfig={setConfig}
            setSelectedItemIds={setSelectedItemIds}
            utilityColumns={utilityColumns}
          />
          <FastDataTableFooter
            footerContent={footerContent}
            showFooterTotals={showFooterTotals}
            preparedColumns={preparedColumns}
            preparedItems={preparedItems}
            utilityColumns={utilityColumns}
          />
        </Fragment>
      </StickyHeaderTable>
      <FastDataTablePagination
        config={config}
        itemCount={
          prepareOnFrontend ? preparedItems.length : itemCountForPagination
        }
        pageDisabled={pageDisabled}
        pageSize={pageSize}
        setConfig={setConfig}
        setPage={setPage}
        simplePaginationControls={simplePaginationControls}
      />
    </Fragment>
  );
}

FastDataTable.propTypes = {
  columns: PropTypes.array,
  controls: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  disableCollapse: PropTypes.bool,
  empty: PropTypes.node,
  footerContent: PropTypes.node,
  footerTotals: PropTypes.bool,
  getRowState: PropTypes.func,
  hideTableHeader: PropTypes.bool,
  items: PropTypes.array,
  onChange: PropTypes.func,
  onClickRow: PropTypes.func,
  onConfigChange: PropTypes.func,
  onSerialize: PropTypes.func,
  pageSize: PropTypes.number,
  prepareOnFrontend: PropTypes.bool,
  serialized: PropTypes.string,
  simplePaginationControls: PropTypes.bool,
  utilityColumns: PropTypes.array,
};

FastDataTable.defaultProps = {
  columns: [],
  controls: undefined,
  disableCollapse: false,
  empty: "No data available",
  footerContent: null,
  footerTotals: false,
  getRowState: () => {},
  hideTableHeader: false,
  items: [],
  onChange: () => {},
  onClickRow: null,
  onSerialize: () => {},
  pageSize: 100,
  prepareOnFrontend: true,
  serialized: toBase64({
    columnConfig: null,
    filterConfig: [],
    groupConfig: {},
    sortConfig: {},
  }),
  simplePaginationControls: false,
  utilityColumns: [],
};
