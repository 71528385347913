import { useEffect, useState } from "react";
import { useDeepCompareCallback } from "use-deep-compare";
import PropTypes from "prop-types";
import { debounce, get } from "lodash";
import { Form, Pane, Paragraph, SearchInput } from "components/materials";
import { majorScale } from "helpers/utilities";
import { COMPARATORS, filterShape, columnShape } from "../FastDataTableUtils";

const SEARCHABLE_COLUMN_TYPES = ["string", "list", "enum"];

function getSearchColumnOptions(visibleStringColumns) {
  return visibleStringColumns.map((column) => ({
    key: column.id,
    value: column.id,
    text: column.header,
  }));
}

const Search = ({
  columns,
  columnConfig,
  filterConfig,
  filterBy,
  placeholder,
}) => {
  const searchFilter = filterConfig.find(({ __isSearch }) => __isSearch);

  const otherFilters = filterConfig.filter(({ __isSearch }) => !__isSearch);
  const primaryColumn = columns.find((column) => column.primary);

  const filterSearchInput = get(searchFilter, "input", null);
  const filterSearchKey = get(searchFilter, "key", primaryColumn?.id);

  /* Maintain search text, local only to this component.
     The values that should represent the items in the table at any
     moment are contained in filterSearchInput */
  const [localSearchText, setLocalSearchText] = useState(filterSearchInput);

  function updateConfig(searchColumnId, searchValue) {
    const newFilters = otherFilters.concat({
      key: searchColumnId || primaryColumn?.id,
      operator: COMPARATORS.CONTAINS.value,
      input: searchValue || "",
      __isSearch: true,
    });

    filterBy(newFilters);
  }

  /* Updating the config has many side effects that include
     setting the filtered set of items.  Debouncing the call
     to set the config thus also debounces all
     side effects, i.e. setting URL string, preparing items, etc. 
     Note: filterBy here is recomputed (FastDataTableControls.js)
     when config changes, and thus needs to also be in the dependency array */
  const debouncedUpdateConfig = useDeepCompareCallback(
    debounce(updateConfig, 375),
    [filterBy, otherFilters]
  );

  useEffect(() => {
    if (localSearchText !== null) {
      debouncedUpdateConfig(filterSearchKey, localSearchText);
    }
    /* filterSearchKey is not needed in the dependency array as
       a changing of the filterSearchKey invokes an undebounced
       updateConfig in the onChange handler below */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSearchText]);

  /* This manages things like updating the search input if the 
     user selects a saved view */
  useEffect(() => {
    setLocalSearchText(filterSearchInput);
  }, [filterSearchInput]);

  const visibleStringColumns = columns.filter(
    (column) =>
      SEARCHABLE_COLUMN_TYPES.includes(column.type) &&
      !column.hidden &&
      column.filterStrategy !== null &&
      !column.disableSearch &&
      columnConfig.includes(column.id)
  );

  return (
    <Pane display="flex">
      {visibleStringColumns.length > 1 && (
        <Pane marginTop={-23} marginRight={majorScale(1)}>
          <Paragraph size={300}>Search By:</Paragraph>
          <Form.Select
            onChange={(columnId) => updateConfig(columnId, localSearchText)}
            options={getSearchColumnOptions(visibleStringColumns)}
            value={filterSearchKey}
            width={150}
          />
        </Pane>
      )}
      <SearchInput
        marginTop={3}
        onChange={(event) => setLocalSearchText(event.target.value)}
        placeholder={placeholder}
        value={localSearchText ?? ""}
      />
    </Pane>
  );
};

Search.propTypes = {
  columns: PropTypes.arrayOf(columnShape),
  filterConfig: PropTypes.arrayOf(filterShape),
  filterBy: PropTypes.func,
};

export default Search;
