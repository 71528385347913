import { useEffect, useState, useContext } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Loadable } from "components/materials";
import { UserContext } from "helpers/behaviors";
import { get, reject } from "lodash";

const QUERY = gql`
  query ScopeOrganizationOrganizationQuerys {
    organizations {
      id
      name
      permissionConfig
      type
    }
  }
`;

export function ScopeOrganizations({
  children,
  scopeToUserPermission,
  ...props
}) {
  const [selectedOrganization, setSelectedOrganization] = useState(undefined);
  const { scopeOrgsToUserLevelPermission, setScopedOrganization } = useContext(
    UserContext
  );

  useEffect(() => {
    if (selectedOrganization) {
      setScopedOrganization(selectedOrganization);
    }
  }, [selectedOrganization, setScopedOrganization]);

  const scope = (orgs) => {
    if (scopeToUserPermission) {
      return scopeOrgsToUserLevelPermission(orgs, scopeToUserPermission);
    }

    return orgs;
  };

  const { data, loading } = useQuery(QUERY, {
    onCompleted: (result) => {
      const orgs = get(result, "organizations", []);
      // Since potentially no organization has the permission being scoped to,
      // null is a valid default organization
      const defaultOrg = scope(orgs)[0] || null;
      setSelectedOrganization(defaultOrg);
    },
  });

  // The ScopeOrganizations object should only be returned once scoping has completed
  // to prevent unscoped organizations being used as scoped ones
  if (selectedOrganization === undefined || loading)
    return <Loadable loading />;

  const allOrganizations = data.organizations || [];

  const scopedOrganizations = scope(allOrganizations);

  const scopedOrganizationIds = scopedOrganizations.map(({ id }) => id);

  const disabledOrganizations = reject(
    allOrganizations,
    (o) => scopedOrganizationIds.indexOf(o.id) !== -1
  );

  return children({
    ...props,
    allOrganizations,
    disabledOrganizations,
    onOrganizationSelected: (org) => {
      setSelectedOrganization(org);
    },
    organizations: scopedOrganizations,
    selectedOrganization,
  });
}
