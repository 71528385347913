import { flatMap } from "lodash";
import { ConfigureButtons, Pane, Text } from "components/materials";
import { formatTrimDate } from "helpers/dateHelpers";
import { DRAW_STATE } from "helpers/enums";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { defaultViews as defaultDrawReportViews } from "components/templates/ReportsDrawsTable";
import { BlankSlate, BlankSlateType } from "./BlankSlate";
import { LinkCell, Table } from "./Table";
import { CardHeading } from "./CardHeading";
import { CardLinkButton } from "./CardLinkButton";
import { trackClickthrough } from "./helpers";

export const OUTSTANDING_DRAWS_CONFIGURATION_SETTINGS = {
  i: "outstandingDraws",
  x: 0,
  y: 3,
  w: 2,
  h: 1,
  disabled: false,
};

export function OutstandingDraws({
  cards,
  isConfigurable,
  isDisabled,
  name,
  organization,
  projects,
  setCards,
}) {
  const organizationId = organization.id;

  const relevantDraws = flatMap(
    projects,
    ({ id: projectId, name: projectName, draws }) =>
      draws.map(({ id: drawId, name: drawName, ...draw }) => ({
        ...draw,
        drawId,
        drawName,
        projectId,
        projectName,
      }))
  ).filter((draw) => draw.state !== DRAW_STATE.FUNDED && draw.processingData);

  const rows = relevantDraws.map((draw) => {
    const {
      projectId,
      projectName,
      drawId,
      drawName,
      processingData: { daysToProcess: elapsed, processingStartDate },
    } = draw;

    const submissionDate = formatTrimDate(processingStartDate);
    const elapsedText = `${elapsed} ${elapsed === 1 ? "day" : "days"}`;

    return {
      drawId,
      drawName,
      elapsed,
      elapsedText,
      projectId,
      projectName,
      submissionDate,
    };
  });

  const showBlankSlate = rows.length === 0;

  const columns = [
    {
      name: "Project",
      renderCell: (row) => (
        <Project organizationId={organizationId} row={row} />
      ),
      sortKey: "projectName",
      width: "30%",
    },
    {
      name: "Draw",
      renderCell: (row) => <Draw organizationId={organizationId} row={row} />,
      sortKey: "drawName",
      width: "25%",
    },
    {
      name: "Submission Date",
      sortKey: "submissionDate",
      valueKey: "submissionDate",
      width: "30%",
    },
    {
      name: "Elapsed",
      sortKey: "elapsed",
      valueKey: "elapsedText",
      width: "15%",
    },
  ];

  const cardLinkConfig =
    defaultDrawReportViews.find((view) => view.name === "Outstanding Draws")
      ?.config || "";

  const cardLinkHref = `/reports/draws?referrerSelectedOrgId=${organizationId}&table=${cardLinkConfig}`;

  return (
    <Pane
      alignItems="space-between"
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      padding={majorScale(2)}
    >
      <Pane
        display="flex"
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Outstanding Draws" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <CardLinkButton
            href={cardLinkHref}
            onClick={() =>
              trackClickthrough(
                "Outstanding Draws to Draw Report",
                organizationId
              )
            }
          />
        )}
      </Pane>
      {showBlankSlate ? (
        <BlankSlate type={BlankSlateType.Draws} />
      ) : (
        <Pane
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Table
            cellBorderY={false}
            columns={columns}
            defaultSortDirection="desc"
            defaultSortKey="elapsed"
            hasFooter={false}
            maxHeight="280px"
            rows={rows}
            width="100%"
          />
          <Text
            marginTop={majorScale(2)}
            fontStyle="italic"
            textAlign="center"
            maxWidth="80%"
          >
            {t("portfolioInsights.draws.elapsedNote")}
          </Text>
        </Pane>
      )}
    </Pane>
  );
}

function Project({ organizationId, row }) {
  const { projectId, projectName } = row;

  return (
    <LinkCell
      characterLimit={16}
      name={projectName}
      onClick={() =>
        trackClickthrough("Outstanding Draws to Project", organizationId, {
          projectId,
        })
      }
      url={`/projects/${projectId}`}
    />
  );
}

function Draw({ organizationId, row }) {
  const { drawId, drawName, projectId } = row;

  return (
    <LinkCell
      characterLimit={12}
      name={drawName}
      onClick={() =>
        trackClickthrough("Outstanding Draws to Draw", organizationId, {
          drawId,
          drawName,
          projectId,
        })
      }
      url={`/projects/${projectId}/draws/${drawId}`}
    />
  );
}
