import { useHiddenViewerOnMount } from "helpers/behaviors/InternetExplorerContext";
import { Modal, Pane, Paragraph, Text } from "components/materials";
import { majorScale } from "helpers/utilities";

export function Confirm({
  cancelLabel = "No",
  confirmLabel = "Yes",
  content,
  header,
  hideViewer = false,
  onCancel,
  onConfirm,
  onCloseComplete,
  open,
  warningMessage = "",
  ...props
}) {
  useHiddenViewerOnMount(hideViewer && open);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onClose={onCloseComplete}
      onConfirm={onConfirm}
      title={header}
      hasFooter
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
      {...props}
    >
      <Paragraph>{content}</Paragraph>
      {warningMessage && (
        <Pane marginTop={majorScale(1)}>
          <Text fontWeight={800}>{warningMessage}</Text>
        </Pane>
      )}
    </Modal>
  );
}

export default Confirm;
