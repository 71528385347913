import { useContext, useMemo, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { OrganizationSelector, DocumentTable } from "components/templates";
import { Loadable, Pane } from "components/materials";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { get, omit } from "lodash";
import { formatFastDocuments } from "helpers/documentHelpers";
import { majorScale } from "helpers/utilities";
import { FAST_DOCUMENT_FRAGMENT } from "helpers/fragments";
import t from "helpers/translate";
import { ScopeOrganizations } from "./ScopeOrganizations";
import { Document } from "./Document";

const QUERY = gql`
  query ReportsDocumentsPageInnerQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      productTypes {
        id
        type
      }
      projectRegions {
        id
        region
      }
      projectTemplates {
        id
        name
      }
      teams {
        id
        name
      }
      projects {
        id
        status
        productType {
          id
          type
        }
        projectRegion {
          id
          region
        }
        team {
          id
          name
        }
        template {
          id
          name
        }
        documentReviewers {
          id
          position
          user {
            id
            approvalAmountLimit
            approvalAmountMinimum
            fullName
          }
        }
      }
      fastDocuments {
        id
        ...FastDocumentFragment
      }
    }
  }
  ${FAST_DOCUMENT_FRAGMENT}
`;

function ReportsDocumentsPageInner({
  disabledOrganizations,
  history,
  match,
  onOrganizationSelected,
  organizations,
  selectedOrganization,
}) {
  const { analyticsContext, hasOrgLevelPermission, hasPermission } = useContext(
    UserContext
  );
  const { documentId, projectId } = match.params;
  const { data, loading } = useQuery(QUERY, {
    context: {
      ...analyticsContext,
      analyticsMessage: "Reports Documents Page",
    },
    variables: { organizationId: selectedOrganization.id },
  });

  const documents = get(data, "organization.fastDocuments", []);
  const projects = get(data, "organization.projects", []);

  const tableDocuments = useMemo(
    () => formatFastDocuments(documents, projects),
    [documents, projects]
  );

  const documentReviewersByProject = useMemo(
    () =>
      projects.reduce(
        (dict, project) => ({
          ...dict,
          [project.id]: project.documentReviewers,
        }),
        {}
      ),
    [projects]
  );

  if (loading)
    return (
      <Loadable
        loading
        spinnerText={t("loadingText.documentLoading")}
        textDelay={3000}
        timeout={180000}
      />
    );

  const refetch = [
    { query: QUERY, variables: { organizationId: selectedOrganization.id } },
  ];

  const hasIssues = tableDocuments.some(({ issues }) => issues.length > 0);

  const defaultColumns = ["document"]
    .concat(
      hasPermission(
        PERMISSION_ACTION.RULES_REDESIGN_CLERICAL,
        selectedOrganization
      ) && hasIssues
        ? ["documentIssues"]
        : []
    )
    .concat([
      "type",
      "vendor",
      "currentAmountRequested",
      "uploadedAt",
      "project",
    ])
    .concat(
      hasPermission(PERMISSION_ACTION.ASSIGN_DOCUMENTS, selectedOrganization)
        ? ["assignedTo"]
        : []
    )
    .concat(
      hasOrgLevelPermission(PERMISSION_ACTION.APPROVE_DOCUMENTS)
        ? ["approval"]
        : []
    );

  const defaultGroup = { columnId: null };

  const handleClick = (document) => {
    history.push({
      pathname: `/reports/documentation/projects/${document.projectId}/documents/${document.id}`,
      search: history.location.search,
    });
  };

  return (
    <Fragment>
      <Pane margin={majorScale(2)}>
        <OrganizationSelector
          title="Viewing documents for"
          disabledOrganizations={disabledOrganizations}
          onOrganizationSelected={onOrganizationSelected}
          organizations={organizations}
          selectedOrganization={selectedOrganization}
        />
      </Pane>
      <DocumentTable
        defaultColumns={defaultColumns}
        defaultGroup={defaultGroup}
        deleteRefetch={refetch}
        documents={tableDocuments}
        documentReviewersByProject={documentReviewersByProject}
        history={history}
        match={match}
        onClickDocumentCard={handleClick}
        organizationId={selectedOrganization.id}
        orgData={omit(data.organization, ["projects", "fastDocuments"])}
        pinnedFilters={[
          "projectStatus",
          "projectType",
          "productType",
          "projectRegion",
          "team",
        ]}
        selectedOrganization={selectedOrganization}
        tableName="ReportDocumentTable"
      />
      {documentId && projectId && (
        <Document documents={tableDocuments} history={history} match={match} />
      )}
    </Fragment>
  );
}

export function ReportsDocumentsPage({ history, match }) {
  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.RUN_REPORT}>
      {({
        onOrganizationSelected,
        disabledOrganizations,
        allOrganizations,
        selectedOrganization,
      }) => (
        <ReportsDocumentsPageInner
          history={history}
          match={match}
          disabledOrganizations={disabledOrganizations}
          organizations={allOrganizations}
          onOrganizationSelected={onOrganizationSelected}
          selectedOrganization={selectedOrganization}
        />
      )}
    </ScopeOrganizations>
  );
}
