import { useContext } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Button } from "components/materials";
import { ThemeContext } from "helpers/utilities";
import { css } from "glamor";
import { updateFastDocumentCache } from "helpers/fastDocumentCache";
import {
  DRAW_QUERY as notifyAssigneesDrawQuery,
  PROJECT_QUERY as notifyAssigneesProjectQuery,
} from "./NotifyDocumentAssignees";

const APPROVE = gql`
  mutation ApproveDocuments($documentIds: [String]!) {
    approveDocuments(documentIds: $documentIds) {
      id
      approvedBy {
        id
      }
      approvedUsers {
        id
      }
      assignedUser {
        id
      }
      recentApprovalReviews {
        id
        userId
        userName
        reviewType
        insertedAt
      }
    }
  }
`;

export function ApproveDocumentButton({
  buttonStyles,
  children = null,
  document,
  onApproveCompleted,
  refetchQueries,
  hoverColor,
}) {
  const theme = useContext(ThemeContext);

  const [approve, { loading: approveLoading }] = useMutation(APPROVE, {
    variables: { documentIds: [document.id] },
    onCompleted: onApproveCompleted,
    refetchQueries,
    awaitRefetchQueries: true,
    update: (apolloStore, { data }) => {
      updateFastDocumentCache(apolloStore, data);
    },
  });

  return (
    <Button
      appearance="primary"
      content={children ? null : "Approve"}
      intent="success"
      isLoading={approveLoading}
      purpose="approve-document approve"
      onClick={(event) => {
        event.stopPropagation();
        approve();
      }}
      {...css({
        "&#dashApprove:hover": {
          backgroundColor: hoverColor || theme.colors.gray200,
        },
      })}
      {...buttonStyles}
    >
      {children}
    </Button>
  );
}

export function getNotifyAssigneesQueryObject(match) {
  const { drawId, projectId } = match.params;
  return {
    query: drawId ? notifyAssigneesDrawQuery : notifyAssigneesProjectQuery,
    variables: drawId ? { drawId, projectId } : { projectId },
  };
}

ApproveDocumentButton.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  refetchQueries: PropTypes.array,
};

ApproveDocumentButton.defaultProps = {
  refetchQueries: [],
};
