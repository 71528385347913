import { useEffect, Fragment, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  DrawLockdownBanner,
  FundingSourceWarningBanner,
  FundingSourceDisbursedWarningBanner,
} from "components/templates";
import { Pane, RedesignLayout } from "components/materials";
import {
  PERMISSION_ACTION,
  DOCUMENT_TYPE_NAME,
  DRAW_STATE,
} from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { LOCKED_DRAW_STATES } from "helpers/drawHelpers";
import isRecentDraw from "helpers/isRecentDraw";
import {
  DRAW_HEADER_QUERY,
  DRAW_AMOUNT_SUBSCRIPTION,
  ISSUES_SUBSCRIPTION,
} from "./graphql";
import { DrawDetails } from "./DrawDetails";
import { PrimaryActions } from "./PrimaryActions";
import { DrawNavigation } from "./DrawNavigation";
import { SecondaryActions } from "./SecondaryActions";
import { DrawStatusBar } from "./DrawStatus/DrawStatusBar";

export function DrawHeader({ history, match, projectForFundingSourceWarning }) {
  const { drawId, projectId } = match.params;
  const isNewDraw = drawId === "new";

  const { data, loading: queryLoading, subscribeToMore } = useQuery(
    DRAW_HEADER_QUERY,
    {
      skip: isNewDraw,
      variables: {
        documentType: DOCUMENT_TYPE_NAME.INVOICE,
        drawId,
        projectId,
      },
    }
  );

  if (isNewDraw || queryLoading) return null;

  return (
    <DrawHeaderContent
      data={data}
      drawId={drawId}
      history={history}
      match={match}
      projectForFundingSourceWarning={projectForFundingSourceWarning}
      subscribeToMore={subscribeToMore}
    />
  );
}

function DrawHeaderContent({
  data,
  history,
  match,
  projectForFundingSourceWarning,
  subscribeToMore,
  drawId,
}) {
  const { hasPermission, setProjectId } = useContext(UserContext);

  const { projectId } = match.params;

  useEffect(() => {
    setProjectId(projectId);
  }, [projectId, setProjectId]);

  useEffect(() => {
    return subscribeToMore({
      document: DRAW_AMOUNT_SUBSCRIPTION,
      variables: { drawId },
    });
  }, [drawId, subscribeToMore]);

  useEffect(() => {
    return subscribeToMore({
      document: ISSUES_SUBSCRIPTION,
      variables: { drawId },
    });
  }, [drawId, subscribeToMore]);

  const { project } = data;
  const { drawStateUpdates } = project.draw;
  const draw = {
    ...project.draw,
    recentStateUpdate: drawStateUpdates.length
      ? drawStateUpdates[drawStateUpdates.length - 1]
      : null,
  };
  const { isLockedDown, state } = draw;
  // "draw.isLockedDown" is derived from an organization-level permission
  // the variable defined here, "drawIsInLockedState", is derived from the draw's state and used for different gating
  const drawIsInLockedState = LOCKED_DRAW_STATES.includes(draw.state);

  const isMostRecentDraw = isRecentDraw(data);
  const isIncompleteDraw = state === DRAW_STATE.INCOMPLETE;

  const canEditFundingSources =
    hasPermission(PERMISSION_ACTION.ACCESS_FUNDING_SOURCES) &&
    hasPermission(PERMISSION_ACTION.EDIT_PROJECT_SETTINGS);

  return (
    <Fragment>
      {isLockedDown && <DrawLockdownBanner />}
      <FundingSourceWarningBanner
        project={projectForFundingSourceWarning}
        canEditFundingSources={canEditFundingSources}
      />
      <FundingSourceDisbursedWarningBanner
        project={projectForFundingSourceWarning}
      />
      <Pane elevation={1} paddingX={majorScale(4)} zIndex="2">
        <Pane
          display="flex"
          alignItems="end"
          height={72}
          paddingBottom={majorScale(2)}
        >
          <DrawDetails draw={draw} isIncompleteDraw={isIncompleteDraw} />
          <RedesignLayout.Spacer />
          <PrimaryActions
            project={project}
            draw={draw}
            isIncompleteDraw={isIncompleteDraw}
            isMostRecentDraw={isMostRecentDraw}
            drawIsInLockedState={drawIsInLockedState}
          />
        </Pane>
        <Pane display="flex" alignItems="center">
          <DrawNavigation draw={draw} match={match} />
          <RedesignLayout.Spacer />
          <SecondaryActions
            history={history}
            project={project}
            isMostRecentDraw={isMostRecentDraw}
            drawIsInLockedState={drawIsInLockedState}
          />
        </Pane>
      </Pane>
      <DrawStatusBar draw={draw} project={project} history={history} />
    </Fragment>
  );
}
