import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Tab, Tablist } from "evergreen-ui";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from "victory";
import { get, keys } from "lodash";
import formatPercent from "helpers/formatPercent";
import { ConfigureButtons, Pane } from "components/materials";
import { BRAND_COLORS, COLORS } from "helpers/colors";
import t from "helpers/translate";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { BlankSlate, BlankSlateType } from "./BlankSlate";
import { CardHeading } from "./CardHeading";
import { trackClickthrough } from "./helpers";

const CHART_WIDTH = 520;

export const CONTINGENCY_RISK_CONFIGURATION_SETTINGS = {
  i: "contingencyRisk",
  x: 0,
  y: 1,
  w: 1,
  h: 1.5,
  disabled: false,
};

const GRAPH_FILTER = {
  OVER_TEN: "OVER_TEN",
  FIVE_TO_TEN: "FIVE_TO_TEN",
  WITHIN_FIVE: "WITHIN_FIVE",
  ALL: "ALL",
};

export function ContingencyRisk({
  cards,
  isConfigurable,
  isDisabled,
  name,
  organization,
  projects,
  setCards,
}) {
  const [graphFilter, setGraphFilter] = useState(GRAPH_FILTER.OVER_TEN);

  const organizationId = organization.id;

  const relevantProjects = projects.filter(
    ({ recentDraw }) =>
      get(recentDraw, "hardCostPercentages.percentHardCostContingencyUsed", 0) >
      0
  );

  const data = relevantProjects.map(
    ({
      recentDraw: { hardCostPercentages },
      id: projectId,
      name: projectName,
    }) => {
      const {
        drawName,
        drawState,
        percentHardCostContingencyUsed,
        percentHardCostCompleted,
      } = hardCostPercentages;

      return {
        drawName,
        drawState,
        percentHardCostContingencyUsed,
        percentHardCostCompleted,
        percentContingencyDeviation: Math.abs(
          percentHardCostContingencyUsed - percentHardCostCompleted
        ),
        projectId,
        projectName,
      };
    }
  );

  const filteredData = data.filter(({ percentContingencyDeviation }) => {
    switch (graphFilter) {
      case GRAPH_FILTER.OVER_TEN:
        return percentContingencyDeviation >= 0.1;
      case GRAPH_FILTER.FIVE_TO_TEN:
        return (
          percentContingencyDeviation >= 0.05 &&
          percentContingencyDeviation <= 0.1
        );
      case GRAPH_FILTER.WITHIN_FIVE:
        return percentContingencyDeviation <= 0.05;
      default:
        return true;
    }
  });

  const showBlankSlate = filteredData.length === 0;
  const { GRAPH_COLOR_FOR_FILTER } = useScheduleRiskColors();

  return (
    <Pane
      display="flex"
      flexDirection="column"
      height="100%"
      padding={majorScale(2)}
      width="100%"
    >
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading
          disabled={isDisabled}
          text="Contingency Risk"
          subHeading="Note: The most recent draw for the project is shown if the percentage of hard cost contingency used is
          &gt; 0%"
        />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            name={name}
            setCards={setCards}
          />
        )}
      </Pane>
      <Pane
        alignItems="center"
        display="flex"
        flexGrow="1"
        flexDirection="column"
      >
        {showBlankSlate ? (
          <BlankSlate type={BlankSlateType.ContingencyRisk} />
        ) : (
          <ContingencyRiskGraph
            data={filteredData}
            organizationId={organizationId}
          />
        )}
        <Tablist display="flex">
          {keys(GRAPH_FILTER).map((filter) => {
            const hasLegendIcon = filter !== GRAPH_FILTER.ALL;

            return (
              <Tab
                isSelected={filter === graphFilter}
                key={filter}
                onSelect={() => setGraphFilter(filter)}
              >
                <Pane alignItems="center" display="flex">
                  {hasLegendIcon && (
                    <Pane
                      backgroundColor={GRAPH_COLOR_FOR_FILTER[filter]}
                      borderRadius="50%"
                      height="12px"
                      marginRight={minorScale(1)}
                      width="12px"
                    />
                  )}
                  {t(`portfolioInsights.graphFilter.${filter}`)}
                </Pane>
              </Tab>
            );
          })}
        </Tablist>
      </Pane>
    </Pane>
  );
}

function ContingencyRiskGraph({ data, organizationId }) {
  const history = useHistory();

  const { colorForDeviation } = useScheduleRiskColors();
  const theme = useContext(ThemeContext);

  const graphGray = theme.colors.borderGray;

  const graphData = data.map(
    ({
      drawName,
      drawState,
      percentHardCostContingencyUsed,
      percentHardCostCompleted,
      percentContingencyDeviation,
      projectId,
      projectName,
    }) => {
      return {
        drawName,
        drawState,
        percentHardCostContingencyUsed,
        percentHardCostCompleted,
        deviation: percentContingencyDeviation,
        x: Math.min(percentHardCostContingencyUsed * 100, 100),
        y: Math.min(percentHardCostCompleted * 100, 100),
        projectId,
        projectName,
      };
    }
  );

  const getScatterPointLabelContent = ({
    deviation,
    drawName,
    drawState,
    percentHardCostCompleted,
    percentHardCostContingencyUsed,
    projectName,
  }) =>
    `${projectName}\n${drawName}\n
    \nStatus: ${t(`drawStates.${drawState}`)}
    % Hard Cost Complete: ${formatPercent(percentHardCostCompleted)}
    % Hard Cost Contingency Used: ${formatPercent(
      percentHardCostContingencyUsed
    )}\n \nDeviation from Trend: ${formatPercent(deviation, "-", 1)}`;

  const lineData = [
    { x: 0, y: 0 },
    {
      x: 100,
      y: 100,
    },
  ];

  const onScatterPointClick = (_event, props) => {
    const { projectId, projectName } = props.datum;

    trackClickthrough("Contingency Risk to Project", organizationId, {
      projectId,
      projectName,
    });
    history.push(`/projects/${projectId}`);
  };

  return (
    <VictoryChart
      padding={{ top: 10, bottom: 45, left: 65, right: 40 }}
      height={300}
      width={CHART_WIDTH}
    >
      <VictoryLine
        data={lineData}
        style={{
          data: {
            stroke: graphGray,
            strokeDasharray: "14, 12",
          },
        }}
      />
      <VictoryAxis
        axisLabelComponent={<VictoryLabel y={300} />}
        crossAxis
        domain={[0, 105]}
        label="% Hard Cost Contingency Used"
        style={{
          axis: { stroke: graphGray },
          axisLabel: {
            fontSize: 12,
            fontFamily: "Avenir",
            fill: theme.colors.textGray,
          },
          tickLabels: {
            fontSize: 10,
            fontFamily: "Avenir",
            fill: theme.colors.textGray,
          },
        }}
        tickValues={[0, 20, 40, 60, 80, 100]}
      />
      <VictoryAxis
        axisLabelComponent={<VictoryLabel x={15} />}
        dependentAxis
        domain={[0, 105]}
        label="% Hard Cost Complete (Gross)"
        style={{
          axis: { stroke: graphGray },
          axisLabel: {
            fontSize: 12,
            fontFamily: "Avenir",
            fill: theme.colors.textGray,
          },
          grid: { stroke: graphGray },
          tickLabels: {
            fontSize: 10,
            fontFamily: "Avenir",
            fill: theme.colors.textGray,
          },
        }}
        tickValues={[0, 20, 40, 60, 80, 100]}
      />
      <VictoryScatter
        data={graphData}
        events={[
          { target: "data", eventHandlers: { onClick: onScatterPointClick } },
        ]}
        labelComponent={
          <VictoryTooltip
            cornerRadius={5}
            flyoutStyle={{
              fill: COLORS.BLACK,
              height: "42px",
              stroke: 0,
            }}
            style={{
              fill: BRAND_COLORS.WHITE,
              fontFamily: "Avenir",
              fontSize: "12px",
            }}
            orientation={(props) => (props.x > 90 ? "left" : "top")}
          />
        }
        labels={getScatterPointLabelContent}
        size={6}
        style={{
          data: {
            cursor: "pointer",
            fill: ({ deviation }) => colorForDeviation(deviation),
            stroke: "transparent",
            strokeWidth: 10,
          },
        }}
      />
    </VictoryChart>
  );
}

function useScheduleRiskColors() {
  const theme = useContext(ThemeContext);

  const GRAPH_COLOR_FOR_FILTER = {
    [GRAPH_FILTER.OVER_TEN]: theme.graphColorScale[1],
    [GRAPH_FILTER.FIVE_TO_TEN]: theme.graphColorScale[8],
    [GRAPH_FILTER.WITHIN_FIVE]: theme.graphColorScale[10],
  };

  function colorForDeviation(deviation) {
    if (deviation >= 0.1) return GRAPH_COLOR_FOR_FILTER[GRAPH_FILTER.OVER_TEN];
    if (deviation >= 0.05)
      return GRAPH_COLOR_FOR_FILTER[GRAPH_FILTER.FIVE_TO_TEN];

    return GRAPH_COLOR_FOR_FILTER[GRAPH_FILTER.WITHIN_FIVE];
  }

  return { colorForDeviation, GRAPH_COLOR_FOR_FILTER };
}
