import { Fragment, useContext, useState } from "react";
import { flatMap, get, isFinite, keys, maxBy, sumBy } from "lodash";
import { InfoSignIcon } from "evergreen-ui";
import { DateTime } from "luxon";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from "victory";
import {
  ConfigureButtons,
  Divider,
  Pane,
  Tab,
  Tablist,
  Text,
  Tooltip,
} from "components/materials";
import { getLastPeriods } from "helpers/dateHelpersV2";
import { divide, subtract } from "helpers/math";
import t from "helpers/translate";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { defaultViews as defaultDrawReportViews } from "components/templates/ReportsDrawsTable";
import { BlankSlate } from "./BlankSlate";
import { CardHeading } from "./CardHeading";
import { CardLinkButton } from "./CardLinkButton";
import { AverageBadge, AverageBadgeStyle } from "./AverageBadge";
import { trackClickthrough } from "./helpers";

export const DRAW_PROCESSING_TIME_CONFIGURATION_SETTINGS = {
  i: "drawProcessingTime",
  x: 0,
  y: 2,
  w: 2,
  h: 1.25,
  disabled: false,
};

const VIEW = {
  SIX_MONTHS: "SIX_MONTHS",
  QUARTERLY: "QUARTERLY",
};

export function DrawProcessingTime({
  cards,
  isConfigurable,
  isDisabled,
  name,
  organization,
  projects,
  setCards,
}) {
  const [currentView, setCurrentView] = useState(VIEW.SIX_MONTHS);

  const organizationId = organization.id;

  const relevantFundedDraws = flatMap(projects, "draws").filter(
    (draw) => draw.processingData && draw.fundedDate
  );

  const relevantApprovedDraws = flatMap(projects, "draws").filter(
    (draw) => draw.approvalData
  );

  const dateTimeUnit = currentView === VIEW.SIX_MONTHS ? "month" : "quarter";
  const periods = getLastPeriods(dateTimeUnit, 6);

  const periodData = periods.map((interval) => {
    const fundedInInterval = relevantFundedDraws.filter((draw) =>
      interval.contains(
        DateTime.fromISO(draw.processingData?.processingStartDate)
      )
    );

    const avgDaysToFund = fundedInInterval.length
      ? Math.round(
          divide(
            sumBy(fundedInInterval, "processingData.daysToProcess"),
            fundedInInterval.length
          )
        )
      : null;

    const submittedInInterval = relevantApprovedDraws.filter((draw) =>
      interval.contains(
        DateTime.fromISO(draw.approvalData?.readyForApprovalDate)
      )
    );

    const avgDaysToApprove = submittedInInterval.length
      ? Math.round(
          divide(
            sumBy(submittedInInterval, "approvalData.daysToApprove"),
            submittedInInterval.length
          )
        )
      : null;

    return [interval, { avgDaysToFund, avgDaysToApprove }];
  });

  const showBlankSlate =
    relevantApprovedDraws.length === 0 && relevantFundedDraws.length === 0;

  const cardLinkConfig =
    defaultDrawReportViews.find((view) => view.name === "Draw Processing")
      ?.config || "";

  const cardLinkHref = `/reports/draws?referrerSelectedOrgId=${organization.id}&table=${cardLinkConfig}`;

  return (
    <Pane
      display="flex"
      flexDirection="column"
      height="100%"
      padding={majorScale(2)}
      width="100%"
    >
      <Pane
        display="flex"
        height="fit-content"
        justifyContent="space-between"
        marginBottom={minorScale(3)}
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Draw Processing Time" />
        {isConfigurable && (
          <ConfigureButtons
            cards={cards}
            isDisabled={isDisabled}
            name={name}
            setCards={setCards}
          />
        )}
        {!isConfigurable && (
          <CardLinkButton
            href={cardLinkHref}
            onClick={() =>
              trackClickthrough(
                "Draw Processing Time to Draw Report",
                organizationId
              )
            }
          />
        )}
      </Pane>
      {showBlankSlate ? (
        <BlankSlate />
      ) : (
        <Pane display="flex" flexDirection="row" flexGrow="1" width="100%">
          <Pane display="flex" flexDirection="column" width="75%">
            <Tablist>
              {keys(VIEW).map((view) => (
                <Tab
                  isSelected={currentView === view}
                  key={view}
                  onSelect={() => setCurrentView(view)}
                  userSelect="none"
                >
                  {t(`portfolioInsights.graphFilter.${view}`)}
                </Tab>
              ))}
            </Tablist>
            <BarChart currentView={currentView} periodData={periodData} />
          </Pane>
          <Comparison currentView={currentView} periodData={periodData} />
        </Pane>
      )}
    </Pane>
  );
}

function BarChart({ currentView, periodData }) {
  const theme = useContext(ThemeContext);
  const {
    colors: { borderGray: graphGray },
    defaultColors: { textMedium },
    graphColorScale,
  } = theme;

  const dateTimeUnit = currentView === VIEW.SIX_MONTHS ? "month" : "quarter";
  const periods = getLastPeriods(dateTimeUnit, 6);

  const approvedBarData = periodData.reduce(
    (acc, [_interval, { avgDaysToApprove }], index) => {
      if (avgDaysToApprove === null) {
        return acc;
      }

      return [
        ...acc,
        { color: graphColorScale[3], x: index + 0.8, y: avgDaysToApprove },
      ];
    },
    []
  );

  const fundedBarData = periodData.reduce(
    (acc, [_interval, { avgDaysToFund }], index) => {
      if (avgDaysToFund === null) {
        return acc;
      }

      return [
        ...acc,
        { color: graphColorScale[1], x: index + 1.2, y: avgDaysToFund },
      ];
    },
    []
  );

  const data = [...fundedBarData, ...approvedBarData];

  const domainY = Math.max(15, maxBy(data, "y")?.y * 1.1);

  const barStyle = {
    data: { fill: ({ color }) => color },
  };

  return (
    <Fragment>
      <VictoryChart
        height={150}
        padding={{ left: 50, bottom: 25, top: 20, right: 20 }}
      >
        <VictoryAxis
          style={{
            axis: { stroke: graphGray },
            tickLabels: {
              fontSize: 8,
              fontFamily: "Avenir",
              fill: textMedium,
            },
          }}
          tickLabelComponent={<VictoryLabel dy={-5} />}
          tickValues={getPeriodLabels(periods, currentView)}
        />
        <VictoryAxis
          dependentAxis
          label="Avg # Days"
          style={{
            axis: { stroke: graphGray },
            axisLabel: {
              fontSize: 8,
              fontFamily: "Avenir",
              fill: textMedium,
            },
            grid: { stroke: graphGray },
            tickLabels: {
              fontSize: 8,
              fontFamily: "Avenir",
              fill: textMedium,
            },
          }}
          tickCount={3}
          tickLabelComponent={<VictoryLabel dx={5} />}
        />
        <VictoryBar
          barWidth={16}
          data={data}
          domain={{ x: [0, 7], y: [0, domainY] }}
          labelComponent={<BarLabel />}
          labels={({ y }) => Math.round(y)}
          style={barStyle}
        />
      </VictoryChart>
      <ChartLegend />
    </Fragment>
  );
}

function BarLabel(props) {
  const theme = useContext(ThemeContext);
  const {
    charts: { barChartTextDark },
    colors: { white },
  } = theme;

  /* Black label is needed for visibility if label is positioned above a bar
     that is too short for an internal label
  */
  const barTooShortForInternalLabel = props.y > 100;
  const labelColor = barTooShortForInternalLabel ? barChartTextDark : white;

  const overrideProps = {
    dy: barTooShortForInternalLabel ? 5 : 25,
    style: { fontSize: 7, fill: labelColor },
  };

  return <VictoryLabel {...props} {...overrideProps} />;
}

function ChartLegend() {
  const theme = useContext(ThemeContext);
  const {
    colors: { textGray },
    graphColorScale,
  } = theme;

  const legendData = [
    {
      color: graphColorScale[3],
      label: "Avg # days to approve",
      tooltipKey: "portfolioInsights.drawProcessingTime.info.daysToApprove",
    },
    {
      color: graphColorScale[1],
      label: "Avg # days to fund",
      tooltipKey: "portfolioInsights.drawProcessingTime.info.daysToFund",
    },
  ];

  return (
    <Pane
      alignItems="center"
      display="flex"
      flexDirection="column"
      rowGap={majorScale(1)}
      width="100%"
    >
      <Pane
        columnGap={majorScale(3)}
        display="flex"
        justifyContent="space-between"
        width="fit-content"
      >
        {legendData.map(({ color, label, tooltipKey }) => (
          <Pane
            alignItems="center"
            columnGap={minorScale(1)}
            display="flex"
            key={label}
          >
            <Pane
              backgroundColor={color}
              borderRadius="50%"
              height="12px"
              width="12px"
            />
            <Text fontSize="16px">{label}</Text>
            <Tooltip content={t(tooltipKey)}>
              <InfoSignIcon color={textGray} size={16} />
            </Tooltip>
          </Pane>
        ))}
      </Pane>
      <Pane>
        <Text fontStyle="italic">
          {t("portfolioInsights.drawProcessingTime.note")}
        </Text>
      </Pane>
    </Pane>
  );
}

function Comparison({ currentView, periodData }) {
  const theme = useContext(ThemeContext);
  const {
    colors: { borderGray, textGray },
  } = theme;

  const title =
    currentView === VIEW.SIX_MONTHS ? "Current Month" : "Current Quarter";

  const description = `compared to previous ${
    currentView === VIEW.SIX_MONTHS ? "month" : "quarter"
  }`;

  const currentPeriodFundedValue = get(periodData, "5.1.avgDaysToFund");
  const priorPeriodFundedValue = get(periodData, "4.1.avgDaysToFund");

  const currentPeriodApprovalValue = get(periodData, "5.1.avgDaysToApprove");
  const priorPeriodApprovalValue = get(periodData, "4.1.avgDaysToApprove");

  const showDaysToFundTrend =
    isFinite(currentPeriodFundedValue) && isFinite(priorPeriodFundedValue);
  const showDaysToApproveTrend =
    isFinite(currentPeriodApprovalValue) && isFinite(priorPeriodApprovalValue);

  const daysToFundTrend = divide(
    subtract(currentPeriodFundedValue, priorPeriodFundedValue),
    priorPeriodFundedValue
  );

  const daysToApproveTrend = divide(
    subtract(currentPeriodApprovalValue, priorPeriodApprovalValue),
    priorPeriodApprovalValue
  );

  const daysToFundTrendProps = {
    style:
      daysToFundTrend < 0
        ? AverageBadgeStyle.Normal
        : AverageBadgeStyle.Warning,
    value: daysToFundTrend,
  };

  const daysToApproveTrendProps = {
    style:
      daysToApproveTrend < 0
        ? AverageBadgeStyle.Normal
        : AverageBadgeStyle.Warning,
    value: daysToApproveTrend,
  };

  return (
    <Pane
      alignItems="center"
      display="flex"
      flexDirection="column"
      flexGrow="1"
      paddingX={majorScale(4)}
    >
      <Pane
        alignItems="center"
        display="flex"
        flexDirection="column"
        rowGap={majorScale(5)}
        width="100%"
      >
        <Pane
          alignItems="center"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <TextMedium fontSize="18px" fontWeight={600}>
            {title}
          </TextMedium>
          <TextMedium fontSize="14px" fontWeight={400}>
            {description}
          </TextMedium>
        </Pane>
        <Pane
          display="flex"
          flexDirection="column"
          rowGap={majorScale(2)}
          width="100%"
        >
          <Pane columnGap={majorScale(1)} display="flex">
            <TextMedium>Avg # Days to Approve</TextMedium>
            <Tooltip
              content={t(
                "portfolioInsights.drawProcessingTime.info.daysToApprove"
              )}
            >
              <InfoSignIcon color={textGray} size={20} />
            </Tooltip>
          </Pane>
          <Pane
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Text fontSize="25px" fontWeight={500}>
              {currentPeriodApprovalValue ?? "N/A"}
            </Text>
            {showDaysToApproveTrend && (
              <AverageBadge {...daysToApproveTrendProps} />
            )}
          </Pane>
          <Divider color={borderGray} height="1px" />
          <Pane columnGap={majorScale(1)} display="flex">
            <TextMedium>Avg # Days to Fund</TextMedium>
            <Tooltip
              content={t(
                "portfolioInsights.drawProcessingTime.info.daysToFund"
              )}
            >
              <InfoSignIcon color={textGray} size={20} />
            </Tooltip>
          </Pane>
          <Pane
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Text fontSize="25px" fontWeight={500}>
              {currentPeriodFundedValue ?? "N/A"}
            </Text>
            {showDaysToFundTrend && <AverageBadge {...daysToFundTrendProps} />}
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}

function TextMedium(props) {
  const theme = useContext(ThemeContext);
  const { textMedium } = theme.defaultColors;

  return <Text color={textMedium} {...props} />;
}

function getPeriodLabels(periods, currentView) {
  switch (currentView) {
    case VIEW.QUARTERLY:
      return periods.map((period) => period.start.toFormat("Qq yy"));
    case VIEW.SIX_MONTHS:
    default:
      return periods.map((period) => period.start.toFormat("M/yy"));
  }
}
